.qr-code-generator {
  text-align: center;
  padding: 20px;
  padding-left: 0px;
}

.qr-code {
  margin-top: 20px;
}

.button-container button {
  background: #283a99;
  color: #fff;
  padding: 10px;
  border: none;
  margin: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 7px;
}

.button-container button:hover {
  background: #283a999c;
}

.fa {
  margin-right: 5px;
}
