@media (max-width: 450px){
  .height{
    height: 100vh;
  }
  .img{
    width: 50%;
  }
 
}

.hoverIcon:hover{
transform: scale(1.4);
}